import React from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import AboutTwo from "@/components/about-two";
import CallToActionThree from "@/components/call-to-action-three";
import AboutThree from "@/components/about-three";
import ServiceTwo from "@/components/service-two";
import HeaderOne from "@/components/header-one";
import SliderOne from "@/components/slider-one";
import MenuContextProvider from "@/context/menu-context";
import ClientCarousel from "@/components/client-carousel";

const Home = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="Inicio">
        <HeaderOne />
        <SliderOne />
        <AboutThree />
        <CallToActionThree />
        <ServiceTwo />
        <ClientCarousel />
        <AboutTwo />
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default Home;

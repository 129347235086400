import React from "react";
import serviceTwo1 from "@/images/what-we-do-1-1.jpg";
import serviceTwo2 from "@/images/what-we-do-1-1-1.jpg";
import serviceTwo3 from "@/images/videoconferencia.jpg";
import serviceTwo4 from "@/images/camaras.jpg";
const ServiceTwo = () => {
  return (
    <section className="what-we-do sec-pad">
      <div className="thm-container">
        <div className="sec-title text-center">
          <span>Productos</span>
          <h3>Productos que ofrecemos</h3>
          <p>
            Contamos con una amplia gama de productos para satisfacer las
            <br /> necesidades de su negocio.
          </p>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="single-what-we-do">
              <div className="img-box">
                <img src={serviceTwo1} alt="" />
              </div>
              <div className="text-box hvr-bounce-to-bottom">
                <a href="#">
                  <h3>Impresoras y Multifuncionales</h3>
                </a>
                <p>
                  Manejamos las mejores marcas del mercado en impresoras y
                  multifuncionales para su negocio o empresa con la mejor
                  calidad y precio.
                </p>
                
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="single-what-we-do">
              <div className="img-box">
                <img src={serviceTwo2} alt="" />
              </div>
              <div className="text-box hvr-bounce-to-bottom">
                <a href="#">
                  <h3>Computadoras y Laptops</h3>
                </a>
                <p>
                  Ofrecemos los modelos más recientes de computadoras y laptops
                  para todo tipo de necesidades, desde uso personal hasta
                  empresarial.
                </p>
                
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="single-what-we-do">
              <div className="img-box">
                <img src={serviceTwo3} alt="" />
              </div>
              <div className="text-box hvr-bounce-to-bottom">
                <a href="#">
                  <h3>Videoconferencias y Reuniones</h3>
                </a>
                <p>
                  Televisores, proyectores, cámaras, micrófonos, bocinas
                  inteligentes, todo lo necesario para que sus reuniones y
                  videoconferencias sean un éxito.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="single-what-we-do">
              <div className="img-box">
                <img src={serviceTwo4} alt="" />
              </div>
              <div className="text-box hvr-bounce-to-bottom">
                <a href="#">
                  <h3>Seguridad y Vigilancia</h3>
                </a>
                <p>
                  Siempre es importante mantener seguros nuestros bienes y
                  propiedades, por eso contamos con las mejores cámaras de
                  seguridad y controles de acceso para su hogar o negocio.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceTwo;
